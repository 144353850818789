const checkmarkIcon =
  "https://cdn.builder.io/api/v1/image/assets/TEMP/438a9418fbcbb73518a0a818de6b87317bfdff2f57f5a7eaedcac1c8307c6571?apiKey=b2d053928e754d30be4e4f99aa30b407&";
const glassesIcon =
  "https://cdn.builder.io/api/v1/image/assets/TEMP/3f933b91b8549267ebbfda54531bfa62a200c9498d53ce41f167c8dda6e746ae?apiKey=b2d053928e754d30be4e4f99aa30b407&";
const lightingIcon =
  "https://cdn.builder.io/api/v1/image/assets/TEMP/a1f88a0e371fe7d4b6c3129f000faf5442b95cfe2b6cacffe8698fe33fed9fd4?apiKey=b2d053928e754d30be4e4f99aa30b407&";

export const instructionData = [
  {
    icon: checkmarkIcon,
    text: "you-are-required-to-take-a-picture-of-your-face",
  },
  {
    icon: glassesIcon,
    text: "please-make-sure-to-take-off",
  },
  {
    icon: lightingIcon,
    text: "for-an-accurate-result",
  },
];
