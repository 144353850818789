import React from "react";
import { useNavigate } from "react-router-dom";

function OCR() {
  const navigate = useNavigate();
  const hiddenButtonRef = React.useRef(null);
  const [image, setImage] = React.useState("");
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    const ocrMessageListener = ({ data: { data, subject } }) => {
      switch (subject) {
        case "LicenseVerification.Error":
          alert(data.message);
          navigate(-1);
          global.OCRSDK.onDestroy();
          break;
        case "LicenseVerification.Invalid":
          alert(data.message);
          navigate(-1);
          global.OCRSDK.onDestroy();
          break;
        case "LicenseVerification.Undefined":
          alert(data.message);
          navigate(-1);
          global.OCRSDK.onDestroy();
          break;
        case "OCR.Verbose":
          alert(data.message);
          break;
        case "Internal.Error":
          switch (data.message) {
            case "SANDBOX_ID_NOT_ALLOWED":
              alert("Sandbox ID tidak diizinkan");
              global.OCRSDK.onDestroy();
              break;
            case "INSUFFICIENT_QUOTA":
              alert("Kuota verifikasi KTP telah habis");
              global.OCRSDK.onDestroy();
              break;
            case "INTERNAL_SERVER_ERROR":
              alert("Mohon pastikan data yang terisi sudah sesuai dengan KTP.");
              break;
            case "PROCESS_TIMEOUT":
              alert("Waktu verifikasi KTP telah habis");
              break;
            default:
              alert("Sistem mengalami gangguan, mohon coba beberapa saat lagi.");
              break;
          }
          break;
        case "OCR.Success":
          setImage(data.image.url);
          setData(JSON.stringify(data, undefined, 2));
          if (data?.failed_attempts)
            window.location.replace(userEkyc?.redirect_url);
          setTimeout(() => {
            navigate("/ekyc", {
              state: {
                step: 4,
                dataKTP: data,
              },
            });
            global.OCRSDK.onDestroy();
          }, 3000);
          break;
        case "OCR.Cancelled":
          navigate(-1);
          break;
        case "OCR.OutOfChance":
          global.OCRSDK.onDestroy();
          navigate(-1);
          break;
        case "Camera.NotFound":
          alert("Kamera tidak terdeteksi");
          global.OCRSDK.onDestroy();
          navigate(-1);
          break;
        case "Camera.PermissionDenied":
          alert("Izin kamera tidak diberikan");
          global.OCRSDK.onDestroy();
          navigate(-1);
          break;
      }
    };
    window.addEventListener("message", ocrMessageListener);
    hiddenButtonRef.current.click();

    return () => {
      window.OCRSDK.onDestroy();
      window.removeEventListener("message", ocrMessageListener);
    };
  }, []);

  const doOCRProcess = React.useCallback(() => {
    window.OCRSDK ? window.OCRSDK.onStart() : navigate(-1);
  }, []);
  return (
    <>
      <button
        ref={hiddenButtonRef}
        style={{ display: "none" }}
        onClick={doOCRProcess}
      >
        Run OCR Process
      </button>
    </>
  );
}

export default OCR;
