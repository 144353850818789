import React from "react";
import { useNavigate } from "react-router-dom";

function OCR() {
  const navigate = useNavigate();
  const hiddenButtonRef = React.useRef(null);
  const [image, setImage] = React.useState("");
  const [data, setData] = React.useState(null);
  const language = window.localStorage.getItem("language") || "id";

  React.useEffect(() => {
    const ocrMessageListener = ({ data: { data, subject } }) => {
      switch (subject) {
        case "Internal.undefined":
          alert(language=="id"?"Verifikasi KTP belum berhasil":"KTP verification has not been successful");
          navigate(-1);
          global.OCRSDK.onDestroy();
          break;
        case "LicenseVerification.Error":
          alert(data.message);
          navigate(-1);
          global.OCRSDK.onDestroy();
          break;
        case "LicenseVerification.Invalid":
          alert(data.message);
          navigate(-1);
          global.OCRSDK.onDestroy();
          break;
        case "LicenseVerification.Undefined":
          alert(data.message);
          navigate(-1);
          global.OCRSDK.onDestroy();
          break;
        case "OCR.Verbose":
          alert(data.message);
          break;
        case "Internal.Error":
          switch (data.message) {
            case "SANDBOX_ID_NOT_ALLOWED":
              alert("Sandbox ID tidak diizinkan");
              global.OCRSDK.onDestroy();
              navigate(-1);
              break;
            case "INSUFFICIENT_QUOTA":
              alert(language=="id"?"Kuota verifikasi KTP telah habis":"KTP verification quota has run out");
              global.OCRSDK.onDestroy();
              navigate(-1);
              break;
            case "INTERNAL_SERVER_ERROR":
                alert(language=="id"?"Mohon pastikan data KTP yang terisi sudah sesuai dengan data yang ada di sistem Prudential. Apabila ada perubahan pada data KTP silakan melakukan pengkinian data dengan menghubungi customer line Prudential atau Prudential Syariah.":"Please make sure that the filled KTP data is in accordance with the data in the Prudential system. If there are changes to the KTP data, please update the data by contacting the Prudential or Prudential Syariah customer line.");
              global.OCRSDK.onDestroy();
              navigate(-1);
              break;
            case "PROCESS_TIMEOUT":
              alert(language=="id"?"Waktu verifikasi KTP telah habis":"KTP verification time has run out");
              global.OCRSDK.onDestroy();
              navigate(-1);
              break;
            default:
              alert(language=="id"?"Sistem mengalami gangguan, mohon coba beberapa saat lagi.":"The system is experiencing interference, please try again later.");
              global.OCRSDK.onDestroy();
              navigate(-1);
              break;
          }
          break;
        case "OCR.Success":
          setImage(data.image.url);
          setData(JSON.stringify(data, undefined, 2));
          if (data?.failed_attempts)
            window.location.replace(userEkyc?.redirect_url);
          setTimeout(() => {
            navigate("/ekyc", {
              state: {
                step: 4,
                dataKTP: data,
              },
            });
            global.OCRSDK.onDestroy();
          }, 3000);
          break;
        case "OCR.Cancelled":
          navigate(-1);
          global.OCRSDK.onDestroy();
          break;
        case "OCR.OutOfChance":
          global.OCRSDK.onDestroy();
          navigate(-1);
          break;
        case "Camera.NotFound":
          alert(language=="id"?"Kamera tidak terdeteksi":"Camera not detected");
          global.OCRSDK.onDestroy();
          navigate(-1);
          break;
        case "Camera.PermissionDenied":
          alert(language=="id"?"Izin kamera tidak diberikan":"Camera permission not granted");
          global.OCRSDK.onDestroy();
          navigate(-1);
          break;
      }
    };
    window.addEventListener("message", ocrMessageListener);
    hiddenButtonRef.current.click();

    return () => {
      window.OCRSDK.onDestroy();
      window.removeEventListener("message", ocrMessageListener);
    };
  }, []);

  const doOCRProcess = React.useCallback(() => {
    window.OCRSDK ? window.OCRSDK.onStart() : navigate(-1);
  }, []);
  return (
    <>
      <button
        ref={hiddenButtonRef}
        style={{ display: "none" }}
        onClick={doOCRProcess}
      >
        Run OCR Process
      </button>
    </>
  );
}

export default OCR;
