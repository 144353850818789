import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LanguagePolisku } from "@pru-cnp/ui-polisku";
import { aesDecrypt, aesEncrypt } from "@pru-cnp/ui-polisku";
import { Typography, Button, Box } from "@mui/material";
import styled from "@emotion/styled";

import KTPStep, { STEP_KTP } from "../components/KTPStep";
import FaceStep from "../components/FaceStep";
import { instructionData } from "../components/FaceIcon";

import Person from "../assets/face-img.jpg";
import KTPImg from "../assets/ktp-img.jpg";

function KYC() {
  const { echo } = LanguagePolisku();

  const { state } = useLocation();
  const navigate = useNavigate();

  const [step, setStep] = React.useState(
    window.location.pathname === "/liveness" ? 5 : state?.step || 1
  );

  const handleNext = () => {
    setStep(step + 1);
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        height: step === 1 ? "100vh" : "100%",
        flexDirection: "column",
        padding: 5,
        backgroundColor: "#f7f7f7",
        [theme.breakpoints.up("xl")]: {
          height: "100vh",
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          [theme.breakpoints.up("lg")]: {
            flex: 2,
            flexDirection: "row",
            justifyContent: "center",
          },
        })}
      >
        <div style={{ flex: 1 }}>
          {step > 4 ? (
            <FaceStep />
          ) : (
            <KTPStep kycStep={step} handleNext={handleNext} />
          )}
          <Button
            onClick={() => {
              if (step > 4) {
                navigate("/ekyc/face", {
                  state: {
                    isLiveness: window.location.pathname === "/liveness",
                  },
                });
              } else handleNext();
            }}
            sx={{
              justifyContent: "center",
              borderRadius: "30px",
              backgroundColor: "#ed1a2d",
              color: "var(--Primary-Color-White, #FFF)",
              whiteSpace: "nowrap",
              textAlign: "center",
              padding: "12px 22px",
              font: "600 12px/156% Open Sans, sans-serif",
              border: "none",
              cursor: "pointer",
              width: 100,
              textTransform: "none",
              marginTop: "20px",
              "&:hover": {
                backgroundColor: "var(--Primary-Color-Prudential-Red, #ED1B2E)",
              },
              display: step === 4 ? "none" : "block",
            }}
          >
            {echo("continue")}
          </Button>
        </div>
        <Box
          sx={(theme) => ({
            display: step === 4 ? "none" : "flex",
            justifyContent: "flex-end",
            height: "32vh",
            width: "620px",
            backgroundColor: "#e5eaef",
            display: step === 4 ? "none" : "hidden",
            marginRight: "-500px",
            [theme.breakpoints.down("lg")]: {
              display: "none",
            },
          })}
        />
        <Box
          sx={(theme) => ({
            flex: 1,
            marginTop: "70px",
            display: {
              xs: "none",
              lg: step === 4 ? "none" : "block",
            },
          })}
        >
          <img
            height="400px"
            width="500px"
            style={{
              width: "500px",
              height: "400px",
              objectFit: "cover",
            }}
            src={step > 4 ? Person : KTPImg}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default KYC;
