import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import {
  NavbarPolisku,
  FooterPolisku,
  AuthProvider,
  useAuth,
  aesDecrypt,
  aesEncrypt,
  useAxios,
} from "@pru-cnp/ui-polisku";
import KYC from "./pages/KYC";
import OCR from "./pages/OCR";
import Face from "./pages/Face";

function ekycBaseUrl() {
  if (process.env.NODE_ENV === "production")
    return "https://polisku.prudential.co.id/ekyc-common";
  if (process.env.NODE_ENV === "development")
    return "https://polisku-uat.prudential.co.id/ekyc-common";
  return "https://polisku-uat.prudential.co.id/ekyc-common";
}

export const EKYC_COMMON_HOST = ekycBaseUrl();

function NotFound() {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <h1>404 Halaman Tidak Ditemukan</h1>
    </div>
  );
}

export default function Root() {
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));

  const excludedRoutes = ["/ekyc/face", "/ekyc/ocr"];

  const [pathname, setPathname] = useState(window.location.pathname);

  useEffect(() => {
    const handleLocationChange = () => {
      setPathname(window.location.pathname);
    };

    window.addEventListener("popstate", handleLocationChange);

    const stringParams = document.location.search;
    const searchParams = new URLSearchParams(stringParams);
    const onBoardId = searchParams.get("onBoardId");
    const language = searchParams.get("language");
    const isSharia = searchParams.get("isSharia");
    if (onBoardId) window.localStorage.setItem("onBoardId", onBoardId);
    if (language) window.localStorage.setItem("language", language);
    if (isSharia) window.localStorage.setItem("isSharia", isSharia);

    return () => window.removeEventListener("popstate", handleLocationChange);
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider userData={user}>
        {!excludedRoutes.includes(window.location.pathname) && (
          <NavbarPolisku />
        )}
        <Routes>
          <Route path="/liveness" element={<KYC />} />
          <Route path="/ekyc" element={<KYC />} />
          <Route path="/ekyc/face" element={<Face />} />
          <Route path="/ekyc/ocr" element={<OCR />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        {!excludedRoutes.includes(window.location.pathname) && (
          <FooterPolisku />
        )}
      </AuthProvider>
    </BrowserRouter>
  );
}
