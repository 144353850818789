import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  aesEncrypt,
  aesDecrypt,
  useAxios,
  LanguagePolisku,
} from "@pru-cnp/ui-polisku";

function Face() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const hiddenButtonRef = React.useRef(null);
  const location = useLocation();
  const [image, setImage] = React.useState("");
  const [data, setData] = React.useState("");
  const language = window.localStorage.getItem("language") || "id";
  const { echo } = LanguagePolisku();

  React.useEffect(() => {
    const userEkycStorage = window.localStorage.getItem("userEkyc");
    const userEkyc =
      !userEkycStorage || userEkycStorage === "null"
        ? userEkycStorage
        : JSON.parse(aesDecrypt(window.localStorage.getItem("userEkyc")));

    const urlBack = state?.isLiveness ? "/liveness" : "/ekyc";
    const livenessMessageListener = ({ data: { data, subject } }) => {
      switch (subject) {
        case "LicenseVerification.Error":
          alert(data.message);
          navigate(urlBack);
          global.LivenessSDK.onDestroy();
          break;
        case "LicenseVerification.Invalid":
          alert(data.message);
          navigate(urlBack);
          global.OCRSDK.onDestroy();
          break;
        case "LicenseVerification.Undefined":
          alert(data.message);
          navigate(urlBack);
          global.OCRSDK.onDestroy();
          break;
        case "Verification.Verbose":
          break;
        case "Camera.NotAllowed":
        case "Camera.NotFound":
        case "Camera.PermissionDenied":
        case "ScreenOrientation.NotAllowed":
        case "Verification.Disrupted":
          alert(
            "Verifikasi pengambilan foto belum berhasil\n\nMohon maaf pengambilan foto tidak mengikuti instruksi, silakan ulangi pengambilan foto kembali."
          );
          navigate(urlBack);
          break;
        case "Verification.Timeout":
          alert("Waktu pengambilan foto telah habis");
          navigate(urlBack);
          global.LivenessSDK.onDestroy();
          break;

        case "Verification.Success":
          setImage(`data:image/png;base64,${data.image.url}`);
          setData(JSON.stringify(data, undefined, 2));

          if (state?.isLiveness) {
            const isWithdrawal = JSON.parse(
              aesDecrypt(window.localStorage.getItem("withdrawalForm"))
            );
            if (isWithdrawal) {
              navigate("/withdrawal/submit");
              global.LivenessSDK.onDestroy();
              break;
            }
            setTimeout(function () {
              window.location.replace(userEkyc?.redirect_url);
              window.localStorage.removeItem("userEkyc");
              window.localStorage.removeItem("action");
              global.LivenessSDK.onDestroy();
            }, 3000);
            break;
          }

          userEkyc.step = "verification-result";
          window.localStorage.setItem(
            "userEkyc",
            aesEncrypt(JSON.stringify(userEkyc))
          );
          const isThirdFailed = data?.failed_attempts;
          setTimeout(function () {
            navigate("/verification-result", {
              state: {
                KTPFace: {
                  isSuccess: true,
                  isThirdFailed,
                },
              },
            });
            global.LivenessSDK.onDestroy();
          }, 3000);
          break;

        default:
          break;
      }
    };
    window.addEventListener("message", livenessMessageListener);
    hiddenButtonRef.current.click();

    return () => {
      window.location.reload();
      window.LivenessSDK.onDestroy();
      window.removeEventListener("message", livenessMessageListener);
    };
  }, []);

  const doLivenessVerification = React.useCallback(() => {
    window.LivenessSDK ? window.LivenessSDK.onStart(false) : navigate("/ekyc");
  }, []);

  return (
    <>
      <button
        ref={hiddenButtonRef}
        style={{ display: "none" }}
        onClick={doLivenessVerification}
      >
        Run Liveness Verification
      </button>
    </>
  );
}

export default Face;
