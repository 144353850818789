import Information from "../assets/svg/information-circle-outline.svg";
import KTPIcon from "../assets/svg/ktp-icon.svg";
import MailIcon from "../assets/svg/mail-icon.svg";
import PhoneIcon from "../assets/svg/phone-icon.svg";

export const makeSureKTP = [
  {
    icon: KTPIcon,
    text: "ID-KTP",
  },
  {
    icon: PhoneIcon,
    text: "Nomor-telepon",
  },
  {
    icon: MailIcon,
    text: "Alamat-e-mail",
  },
];
export const instructionKTP = [
  {
    imageSrc: Information,
    title: "photo-of-identity-card-must-be-clearly-read",
    description: "photo-of-identity-card-must-be-clearly-read",
  },
  {
    imageSrc: Information,
    title: "identity-card-is-active-and-valid",
    description: "",
  },
  {
    imageSrc: Information,
    title: "identity-card-must-be-original-not-a-copy",
    description: "",
  },
];
